import React from "react"

export default ({ data }) => {
  return (
    // <Layout header={header} footer={footer}>
    //   <SEO title="404" />
    //   <div className="container-fluid my-5">
    //     <div className="row">
    //       <div className="col-12 text-center">
    //         <h2>Page not found</h2>
    //       </div>
    //       <div className="col-12 text-center">
    //         <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    //       </div>
    //     </div>
    //   </div>
    // </Layout>
    <></>
  )
}
